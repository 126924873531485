<template>
    <v-row class="ma-0">
        <v-col cols="6">
            <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Restart Date</v-subheader>
            <v-menu
                v-model="menu"
                :close-on-content-click="false"
                transition="slide-x-transition"
                offset-y
                bottom
                left
                min-width="auto"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field ref="restartDate" v-bind="attrs" v-on="on" placeholder="Restart Date" hide-details="auto"
                        v-model="restartDate" outlined required  @input="clearDate($event)"
                        :error-messages="model.next_due_date && !restartDate ? ['Invalid Date'] : $helpers.errorMsg('name', $v.restartDate, 'Restart Date')"></v-text-field>
                </template>
                <v-date-picker
                    v-model="model.next_due_date"
                    :min="today"
                    @input="dateChange"
                    color="primary lighten-1"
                    header-color="primary"
                ></v-date-picker>
            </v-menu>
        </v-col>
        <v-col cols="12">
            <v-card-actions class="pa-0">
                <v-row class="ma-0 pa-3" align="center" justify="end">
                    <v-btn :disabled="loading" color="primary" class="text-capitalize mr-3" @click="openNotify()">Save</v-btn>
                    <v-btn :disabled="loading" color="secondary" class="text-capitalize" @click="$emit('enableAct', false)">Cancel</v-btn>
                </v-row>
            </v-card-actions>
        </v-col>
        <notify-modal :loading="loading" :modalData="modalData" :open="openModal" @save="saveNotify" @close="openModal = false"/>
    </v-row>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapActions } from 'vuex';

export default {
    props: ['modelData'],
    emits: ['enableAct'],
    components: {
        NotifyModal: () => import("@/pages/admin/sf-opportunity/components/NotifyModal"),
    },
    data: (vm) => ({
        menu: false,
        model: {},
        today: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        modalData: {
            name: 'save',
            items: [],
            tableNotFound: 'No Data Found!.',
            headers: [
                { text: 'Field', value: 'field' },
                { text: 'Previous', value: 'prev' },
                { text: 'New', value: 'new' }
            ]
        },
        openModal: false,
        loading: false,
        defaultModel: {},
        restartDate: ''
    }),
    validations() {
        return {
            restartDate: { required },
        }
    },
    watch: {
        modelData(newVal) {
            this.defaultModel = newVal;
        }
    },
    created() {
        this.defaultModel = this.modelData;
    },
    methods: {
        ...mapActions(['donateSubPause']),

        clearDate(evt){
            if(!evt || (evt && !this.$helpers.dateValidation(evt, 'DD-MM-YYYY'))) {
                this.restartDate = '';
                this.model.next_due_date = !evt ? null : this.today;
            }
        },
        dateChange() {
            this.restartDate = this.$helpers.getMomentDatas('DD-MM-YYYY', this.model.next_due_date);
            this.menu = false;
        },
        openNotify() {
            this.submit = true;
            this.$v.$touch();
            if (this.$v && this.$v.$invalid || this.cardInValid || this.model.next_due_date && !this.restartDate) {
                this.$helpers.focusErrorElement(this.$v.model, this.$refs);
                return;
            }
            this.modalData.items = [{field: 'Restart Date', prev: this.defaultModel.next_due_date,
                new: this.model.next_due_date }]
            this.openModal = true;
            this.$emit('enableAct', true);
        },
        saveNotify() {
            this.loading = true;
            this.donateSubPause({id: this.defaultModel.id, reqData: this.model}).then(resp => {
                this.openModal = false;
                this.loading = false;
                this.$emit('enableAct', false);
            }).catch(err =>  this.loading = false)
        }
    }
}
</script>
